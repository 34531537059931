<template>
  <div class="d-flex justify-center">
    <splide v-if="slides.length > 1" :options= "option" :slides = 'slides'>
      <splide-slide v-for="slide  in slides" :key="slide.id">
        <img class="baner" :src="slide.image_resize" alt="">
      </splide-slide>
    </splide>
    <img v-if="slides.length == 1" class="single-baner" :src="slides[0].image_resize" alt="">
  </div>
</template>
<style lang="scss" scoped>
.single-baner{
    width: 90%;
    height: 145px;
}
.baner{
    width: 315px;
    height: 145px;
}
@media(min-width: 280px){
  .baner{
      width: 260px;
  }
}
@media(min-width: 330px){
  .baner{
      width: 300px;
  }
}
@media(min-width: 400px){
  .baner{
      width: 330px;
  }
}
@media(min-width: 460px){
  .baner{
      width: 400px;
  }
}
@media(min-width: 600px){
  .baner{
      width: 500px;
  }
}
</style>
<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import { mapState } from 'vuex';
import store from '@/store';
import { search_product_tag } from '@/views/shop/search/models/search';
export default {
    components: {
    Splide,
    SplideSlide,
  },
  props: ['option'],
   computed: {
     ...mapState({
       slides: state => state.home.banner,
       search_fild: state => state.search.searchFild
     })
   },
   mounted () {
     for (const image of this.slides) {
      caches.open('banner').then(cache => {
      cache.add(image.image_resize)
    }) 
    }
   },
   methods: {
     search_banner (tag_id) {
       store.dispatch('search_fild', {fild: tag_id, type: 'tags'})
        setTimeout(() => {
          search_product_tag(this.search_fild)
        }, 500);
     }
   }
}
</script>